// This variable file will change per group website.
// Together with the scss variables these are really the only two files you should change.

// Enable/Disable API call caching. This should be enabled on the live server, but for development purposes it might be handy to turn it off.
export const apiCaching = true;

// This variable will be added to each page's HTML <title> tag. The existing value which should be unique per page, will be placed in the {pageTitle} tag.
// A | symbol will automatically be inserted between the {pagetitle} tag and the rest of the string.
export const htmlHeadTitle = '{pageTitle} Graypen Group - Port Agency, Shipping, Logistics, Inspection and IT Services';

window.addCompaniesToFooter = function(container) {
  document.querySelectorAll(container).forEach ((menuItem) => {

    menuItem.insertAdjacentHTML('beforeend', '<a href="https://www.graypen.com" class="nav-item">Graypen Limited</a>');
    menuItem.insertAdjacentHTML('beforeend', '<a href="https://www.gp-shipping.com" class="nav-item">GP Shipping Limited</a>');
    menuItem.insertAdjacentHTML('beforeend', '<a href="https://www.gp-logistics.co.uk" class="nav-item">GP Logistics Limited</a>');
    menuItem.insertAdjacentHTML('beforeend', '<a href="https://www.gp-steel.co.uk" class="nav-item">GP Steel Logistics</a>');
    menuItem.insertAdjacentHTML('beforeend', '<a href="https://www.gpl-customs.co.uk" class="nav-item">GPL Customs Services</a>');
    menuItem.insertAdjacentHTML('beforeend', '<a href="https://www.bennettmarine.co.uk" class="nav-item">Bennett Marine Associates</a>');
    menuItem.insertAdjacentHTML('beforeend', '<a href="https://www.johnstronach.co.uk" class="nav-item">John Stronach</a>');
    menuItem.insertAdjacentHTML('beforeend', '<a href="https://www.jamargroup.co.uk" class="nav-item">Jamar Group</a>');
    menuItem.insertAdjacentHTML('beforeend', '<a href="https://www.harvest-agency.co.uk" class="nav-item">Harvest Agency</a>');
    menuItem.insertAdjacentHTML('beforeend', '<a href="https://www.harvest-chartering.co.uk" class="nav-item">Harvest Chartering</a>');
    menuItem.insertAdjacentHTML('beforeend', '<a href="https://www.more-transport.com" class="nav-item">More Transport</a>');

  });
};

// Menu, used for both the footer and the main menu.
// Sub items are always loaded with AJAX, as such define the container name, and write a function in the onLoad.
// Setting href to null, will create a <div> element rather than an <a>
export const menu = [
  { 'name': 'Home', 'href': '/', 'show': false, 'inGetToKnowUs': false, 'sub': null },
  { 'name': 'Companies', 'href': '/', 'show': false, 'inGetToKnowUs': false, 'sub': { 'function': 'addCompaniesToFooter' } },
  { 'name': 'Privacy Notice', 'href': '/privacy-notice.html', 'show': false, 'inGetToKnowUs': false, 'sub': null },
  { 'name': 'Cookie Statement', 'href': '/cookie-statement.html', 'show': false, 'inGetToKnowUs': false, 'sub': null },
  { 'name': 'Corporate Responsibility', 'href': '/corporate-responsibilities.html', 'show': false, 'inGetToKnowUs': true, 'sub': null },
  { 'name': 'Trading Terms', 'href': '/trading-terms.html', 'show': false, 'inGetToKnowUs': true, 'sub': null },
];

// The home page has a carousel in the hero. Define the images to show and the text to print underneath.
// If you only specify 1 single entry, there won't be a carousel. Just the one image will be shown.
// In order for the compiler to add the right image`s to the dist folder make sure you import them, and then use the
// imported variable as the image source.
import heroSlide1 from '../img/hero/M365-AdobeStock_113215274.jpg';
import heroSlide2 from '../img/hero/M365-AdobeStock_505549092.jpg';
import heroSlide3 from '../img/hero/M365-AdobeStock_552962802.jpg';
import heroSlide4 from '../img/hero/M365-AdobeStock_519662811.jpg';
export const heroCarousel = [
  { 'img': heroSlide1, 'style' : 'object-position: 50% 30%;', 'label': 'The UK\'s Leading Independent Dry Cargo Port Agent' },
  { 'img': heroSlide2, 'style' : 'object-position: 40% 60%;', 'label': 'Graypen, leading the way since 1969' },
  { 'img': heroSlide3, 'style' : 'object-position: 50% 70%;', 'label': 'The UK\'s Leading Independent Tanker Port Agent' },
  { 'img': heroSlide4, 'style' : 'object-position: 50% 50%;', 'label': 'GP Shipping, leading the way since 1996' },
];

// API information, make sure the domain ends with a /
export const api = {
  'domain': 'https://api.graypengroup.com/v1/',
  'company': 'GPN',
  'referrer': 'GraypenGroup'
};

export const gTagKey = 'G-NF518ZFKZW';

// Company information which is displayed in the footer.
export const company = {
  'legalName': 'Graypen Group Limited',
  'registeredInCountry': 'England',
  'registrationNumber': '10333374',
  'registeredAddress': 'Registered Office<br>The Bridge, One Graypen Way,<br>Queens Road, Immingham,<br>DN40 1QN, United Kingdom'
};

// Remove Quality Policy for company
export const removeQualityPolicy = true;

export const hideNews = false;

